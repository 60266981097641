import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import SeoLeftColumn from "../../components/SeoLeftColumn";
import SeoRightColumn from "../../components/SeoRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// 1. define Hreflang data, for href tags and language button
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/seo"
);


const Seo = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        {/* 2. define SEO meta data */}
        <SEO
          title="Tutorials about SEO & SEO Tools"
          lang="en"
          description="Elevate your SEO skills with tutorials on SEO and DIY SEO tools. Gain mastery in keyword research, on-page optimization, and more with our step-by-step guides."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
        // canonical="/en"
        />
        <MainFrontpage>
          <SeoLeftColumn />
          <SeoRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Seo);
